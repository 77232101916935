* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


table th {
  padding: 10px 15px;
  background-color: rgb(77, 77, 77);
  color: white;
  white-space: nowrap;
}
td {
  white-space: nowrap;
}

table td {
  padding: 10px;
}

tr:nth-child(odd){  
  background-color: rgb(202, 202, 202);
}


.input {
  @apply p-3 border-[1px] border-black w-full
}

.feel-c1 > div {
  @apply flex flex-col gap-2
}

.dim {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert-box {
  padding: 20px;
  border-radius: 10px;
  transform: scale(0.5);
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  animation: pop 0.4s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes pop {
  to {
    transform: scale(1);
  }
}

.alert-box h3 {
  font-size: 25px;
}

.alert-box p {
  font-size: 18px;
}

.alert-box .btns {
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  gap: 30px;
}

.btns button {
  padding: 15px 25px;
  background-color: #fadee8;
  font-size: 18px;
  font-weight: bold;
}